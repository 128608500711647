import styles from "./Loading.module.css";

import { dotWave } from 'ldrs'

dotWave.register()


export default function Loading({size="30", speed="0.8", color="black"}) {
    return(
        <div className={styles.wrapper}>
            <l-dot-wave
            size={size}
            speed={speed}
            color={color}
            ></l-dot-wave>
        </div>
        
    )
}
