import styles from "./Model.module.css";

import Chip from "./Chip";
import { useState } from "react";
import { useDrop } from "react-dnd";

const apiUrl = process.env.REACT_APP_ADMIN_API_URL;



const onDrop = (item, model, refresh,  accesses, setLoading) => {
    const username = item.username;
    const fetchGrantModelAccess = async () => {
        try {
            const response = await fetch(apiUrl + "/model/grant", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${localStorage.getItem('idAdminToken')}`
                },
                body: JSON.stringify({
                    "user_id": username,
                    "model": model
                })
            })
            if (!response.ok) {
                throw new Error('Request failed with status code: ' + response.status);
            }
            const data = await response.json();
            console.log(data);
            refresh();
            return data
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    setLoading(true);
    if (!accesses.includes(username)) {
        fetchGrantModelAccess();
    }
    else {
        console.error("Error:AlreadyHavePermission");
    }
    setLoading(false);
}

export default function User({ model, session_count, accesses, setHolding, refresh, _refresh }) {

    const [loading, setLoading] = useState(false)

    const [{ isOver }, drop] = useDrop({
        accept: 'user',
        drop: (item) => onDrop(item, model, _refresh, accesses, setLoading),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });
    return (
        <div>
            {refresh || loading ? (
                <div
                    className={styles.refreshing}
                >
                    <div className={`${styles.container}`}
                    >
                        <div
                            className={styles.wrapper}
                        >
                            <div className={styles.innerwrapper}>
                                <div className={styles.content}>
                                    <p className={styles.model}>{model}</p>
                                    <p className={styles.session_count}>{session_count}</p>
                                </div>
                                <div>
                                    {accesses
                                        .sort((a, b) => a.localeCompare(b))
                                        .map((el, i) => (
                                            <Chip
                                                key={i}
                                                label={el}
                                            />
                                        ))}
                                </div>
                            </div>


                        </div>
                        {isOver ? (
                            <div className={styles.overwrapper}>
                                <p>Grant permission</p>
                            </div>
                        ) : (<div />)}

                    </div>
                    <div className={styles.divider} />
                </div>
            ) : (
                <div
                    ref={drop}
                >
                    <div className={`${styles.container}`}
                    >
                        <div
                            className={styles.wrapper}
                        >
                            <div className={styles.innerwrapper}>
                                <div className={styles.content}>
                                    <p className={styles.model}>{model}</p>
                                    <p className={styles.session_count}>{session_count}</p>
                                </div>
                                <div>
                                    {accesses
                                        .sort((a, b) => a.localeCompare(b))
                                        .map((el, i) => (
                                            <Chip
                                                key={i}
                                                label={el}
                                                model = {model}
                                                refresh={refresh}
                                                setHolding={setHolding}
                                            />
                                        ))}
                                </div>
                            </div>


                        </div>
                        {isOver ? (
                            <div className={styles.overwrapper}>
                                <p>Grant permission</p>
                            </div>
                        ) : (<div />)}

                    </div>
                    <div className={styles.divider} />
                </div>
            )}
        </div>


    );
}