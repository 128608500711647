import styles from "./Register.module.css";
import { useState } from "react";

export default function Register({ refresh }) {

    const apiUrl = process.env.REACT_APP_ADMIN_API_URL;

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const validate = () => {
        const passwordCheck = (password) => {
            if (password.length < 9) {
                return false;
            }
            const hasUpperCase = /[A-Z]/.test(password);
            const hasLowerCase = /[a-z]/.test(password);
            const hasNumber = /\d/.test(password);
            const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        
            if (hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar) {
                return true;
            } else {
                return false;
            }
        }
        const passwordConfirmCheck = (password, confirm) => {
            return password === confirm
        }
        if (!passwordCheck(password)) {
            setError("Password length must be greater than 8 and has at least one of Uppercases, Lowercases, Number and Special Characters.")
            return false
        }
        if (!passwordConfirmCheck(password, confirmPassword)) {
            setError("Password and Confirm Password does not match.")
            return false
        }
        return true
    }

    const register = (e) => {
        e.preventDefault();
        const fetchRegisterUser = async () => {
            try {
                const response = await fetch(apiUrl + "/user/register", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('idAdminToken')}`
                    },
                    body: JSON.stringify({
                        'username': username,
                        'password': password
                    }),
                })
                if (!response.ok) {
                    throw new Error('Request failed with status code: ' + response.status);
                }
                const data = await response.json();
                if (data.statusCode === 200) {
                    setUsername('');
                    setPassword('');
                    setConfirmPassword('');
                    
                    refresh();
                }
                else {
                    
                    setError("Unknow error occurred.")
                    
                }
                setLoading(false);
                return data
            } catch (error) {
                console.error('Error fetching data:', error);
                setError("Unknow error occurred.")
            }
        };
        if (validate()) {
            setLoading(true);
            fetchRegisterUser();
        };
    }

    return (
        <div className={styles.container}>
            <form className={styles.inputs} onSubmit={register}>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => {
                        setUsername(e.target.value)
                        if (error) setError('');
                    }}
                    className={`${styles.username} ${error ? (
                        styles.error
                    ) : ''}`}
                    placeholder="Username"
                    required
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value)
                        if (error) setError('');
                    }}
                    className={`${styles.password} ${error ? styles.error : ''}`}
                    placeholder="Password"
                    required
                />
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value)
                        if (error) setError('');
                    }}
                    className={`${styles.password} ${error ? styles.error : ''}`}
                    placeholder="Confirm Password"
                    required
                />
                {
                    loading ? (
                        <button type="submit" disabled>Register</button>
                    ):(
                        <button type="submit">Register</button>
                    )
                }
                
                <div className={styles.errormsg}>{error}</div>
            </form>
        </div>
    )
}