import { useState, useEffect } from "react";

import styles from "./Confirm.module.css";

export default function Confirm({ show, message, handleConfirm, handleClose }) {

    const handlePreConfirm = () => {
        handleClose();
        handleConfirm(true);
    }

    return (
        <div className={show ? `${styles.modal} ${styles.show}` : styles.modal} onClick={handleClose}>
            <div className={styles.modal_content} onClick={e => e.stopPropagation()}>
                <span className={styles.close} onClick={handleClose}>&times;</span>
                    <div className={styles.wrapper}>
                        <div className={styles.text}>
                        <p>{message}</p>
                        </div>
                        
                        <div className={styles.actions}>
                            <button className={styles.cancel} onClick={handleClose}>Cancel</button>
                            <button className={styles.confirm} onClick={handlePreConfirm}>Confirm</button>
                        </div>
                    </div>
            </div>
        </div>

    )
}