import styles from "./DeleteUser.module.css";

import Confirm from "./Confirm";

import { useState, useEffect } from "react";
import { useDrop } from "react-dnd";

const apiUrl = process.env.REACT_APP_ADMIN_API_URL;

export default function DeleteUser({ show, refresh }) {

    const [username, setUsername] = useState("")

    const [loading, setLoading] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)

    useEffect(() => {
        if (isConfirm && username !== "") {
            setIsConfirm(false)
            handleUserDelete(username)
        }
        return () => {

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConfirm, username]);

    const handleUserDelete = (item) => {
        const username = item;
        const fetchUserDelete = async () => {
            try {
                const response = await fetch(apiUrl + "/user/delete", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('idAdminToken')}`
                    },
                    body: JSON.stringify({
                        "user_id": username
                    })
                })
                if (!response.ok) {
                    throw new Error('Request failed with status code: ' + response.status);
                }
                const data = await response.json();
                refresh();
                return data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        setLoading(true);
        fetchUserDelete();
        setLoading(false)
    }

    const onDrop = (item) => {
        setUsername(item.username);

        setConfirm(true)
    }

    const onCancel = () => {
        setConfirm(false)
    }


    const [{ isOver }, drop] = useDrop({
        accept: 'user',
        drop: (item) => onDrop(item),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });
    return (
        <div>
            {show && (!loading) ? (
                <div className={styles.delete_header}>
                    <div className={styles.delete_box}>
                        <div
                            ref={drop}
                            className={styles.container}
                        >
                            <p>Delete</p>
                        </div>
                    </div>
                </div>
            ) : (<div />)}


            {true ? (
                <Confirm
                    show={confirm}
                    message={`Are you sure you want to delete "${username}"?`}
                    handleConfirm={setIsConfirm}
                    handleClose={onCancel}
                />) : (<div />)}
        </div>


    );
}