import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

import Login from "./components/Login";
import Register from "./components/Register";
import User from "./components/User";
import Model from "./components/Model";
import DeleteUser from "./components/DeleteUser";
import RevokePermission from "./components/RevokePermission";

import refresh_icon from "./icons/refresh-icon.png";
import logout_icon from "./icons/logout-icon.png"

import './App.css';

function App() {

	const apiUrl = process.env.REACT_APP_ADMIN_API_URL;

	const [data, setData] = useState({
		models: [],
		sessions: [],
		users: [],
		accesses: []
	})

	const [userCount, setUserCount] = useState(0)
	const [sessionCount, setSessionCount] = useState(0)
	const [modelCount, setModelCount] = useState(0)

	const [loginModal, setLoginModal] = useState(true)

	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [isRefreshing, setIsRefreshing] = useState(false)
	const [isHoldingUser, setIsHoldingUser] = useState(false)
	const [isHoldingPermission, setIsHoldingPermission] = useState(false)

	const refresh = async () => {
		const fetchStats = async () => {
			try {
				const response = await fetch(apiUrl + "/", {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `${localStorage.getItem('idAdminToken')}`
					},
				})
				if (!response.ok) {
					throw new Error('Request failed with status code: ' + response.status);
				}
				const data = await response.json();
				return data
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		if (!isRefreshing) {
			setIsRefreshing(true)
			try {
				const data = await fetchStats();
				setData(data.data)
				setUserCount(data.data.users.length)
				setSessionCount(data.data.sessions.length)
				setModelCount(data.data.models.length)
			} catch (error) {
				console.error('Error fetching data:', error);
			}

			setIsRefreshing(false)
		}
	}

	useEffect(() => {
		const checkLoggedIn = () => {
			try {
				const accessToken = localStorage.getItem('accessAdminToken')
				const idToken = localStorage.getItem('idAdminToken')
				if (accessToken !== "null" && idToken !== "null" && accessToken && idToken) {
					const decodedAccessToken = jwtDecode(accessToken);
					const currentTime = Date.now() / 1000;
					const isExpired = decodedAccessToken.exp < currentTime;
					if (isExpired) {
						localStorage.setItem('accessAdminToken', null);
					}
					else {
						setIsLoggedIn(true)
					}
				}
				else {
					setLoginModal(true)
				}
			}
			catch (error) {
				console.error('Error logging in:', error);
			}
		}
		if (!isLoggedIn) {
			checkLoggedIn();

		}
		else {
			setLoginModal(false);

		}
		refresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn]);

	const handleLogout = () => {
		localStorage.setItem('accessAdminToken', null);
		localStorage.setItem('idAdminToken', null)
		setIsLoggedIn(false);
		window.location.href = '/';
	}

	const handleCloseModal = () => {
		setLoginModal(false)
	}

	return (
		<div className="App">
			<div className="Control">

				{isRefreshing ? (
					<button
						onClick={refresh}
						disabled
					><img
							src={refresh_icon}
							alt="refresh"
							className={`Icon ${isRefreshing ? "Refreshing" : ""}`}
						/>
					</button>
				) : (
					<button
						onClick={refresh}
						className={`${isRefreshing ? "Refreshing" : ""}`}
					><img
							src={refresh_icon}
							alt="refresh"
							className={`Icon`}
						/>
					</button>
				)}

				<button
					onClick={handleLogout}
				>
					<img
						src={logout_icon}
						alt="logout"
						className="Icon"
					/>
				</button>
			</div>
			<div className="Dashboard">
				<div className="Halves">
					<div className="Triplets">
						<div className="Card">
							<h1 className="FloatHeader">Users</h1>
							<div className={`Stats ${isRefreshing ? 'Refreshing' : ''}`}><p>{userCount}</p></div>
						</div>
						<div className="Card">
							<h1 className="FloatHeader">Sessions</h1>
							<div className={`Stats ${isRefreshing ? 'Refreshing' : ''}`}><p className="Stats">{sessionCount}</p></div>
						</div>
						<div className="Card">
							<h1 className="FloatHeader">Models</h1>
							<div className={`Stats ${isRefreshing ? 'Refreshing' : ''}`}><p className="Stats">{modelCount}</p></div>
						</div>
					</div>

					<div className="Card">
						<h1>Register</h1>
						<Register
							refresh={refresh}
						/>
					</div>
				</div>

				<div className="Card">
					<h1>Users</h1>
					<div className="List">
						{data.users
							.sort((a, b) => a.Username.localeCompare(b.Username))
							.map((el, i) => {
								return (
									<User
										key={i}
										username={el.Username}
										session_count={data.sessions.filter(obj => obj.UserId === el.Username).length}
										refresh={isRefreshing}
										setHolding={setIsHoldingUser}
									/>
								);
							})}
					</div>
					<DeleteUser
					show={isHoldingUser}
					refresh={refresh}
					/>

				</div>
				<div className="Card">
					<h1>Models</h1>
					<div className="List">
						{data.accesses
							.sort((a, b) => a.Model.localeCompare(b.Model))
							.map((el, i) => {
								return (
									<Model
										key={i}
										model={el.Model}
										session_count={data.sessions.filter(obj => obj.Model === el.Model).length}
										accesses={el.Users}
										setHolding={setIsHoldingPermission}
										refresh={isRefreshing}
										_refresh={refresh}
									/>
								);
							})}
					</div>
					<RevokePermission
					show={isHoldingPermission}
					refresh={refresh}
					/>
				</div>
			</div>

			<div>
				{loginModal ? (<div>
					<Login
						show={loginModal}
						handleClose={handleCloseModal}
					/>
				</div>) : <div />}
			</div>
			<div>
			</div>
		</div>
	);
}

export default App;
