import styles from "./User.module.css";
import { useEffect } from "react";
import { useDrag } from "react-dnd";

const ItemTypes = {
    STRING: 'user',
};

export default function User({ username, session_count, refresh , setHolding}) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.STRING,
        item: { username },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    useEffect(() => {
        
        setHolding(isDragging)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDragging])

    return (
        <div>
            {refresh ? (
                <div
                    className={styles.refreshing}
                >
                    <div className={`${styles.container} ${isDragging ? styles.drag : ""}`}
                    >
                        <div
                            className={styles.wrapper}
                        >
                            <div className={styles.content}>
                                <p className={styles.username}>{username}</p>
                                <p className={styles.session_count}>{session_count}</p>
                            </div>

                        </div>
                    </div>
                    <div className={styles.divider} />
                </div>
            ) : (
                <div
                    ref={drag}
                >
                    <div className={`${styles.container} ${isDragging ? styles.drag : ""}`}
                    >
                        <div
                            className={styles.wrapper}
                        >
                            <div className={styles.content}>
                                <p className={styles.username}>{username}</p>
                                <p className={styles.session_count}>{session_count}</p>
                            </div>

                        </div>
                    </div>
                    <div className={styles.divider} />
                </div>
            )}
        </div>


    );
}