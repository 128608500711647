import styles from "./Chip.module.css"
import { useEffect } from "react";
import { useDrag } from "react-dnd";

const ItemTypes = {
    STRING: 'permission',
};

export default function Chip({ label, model, refresh, setHolding }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.STRING,
        item: {
            username: label,
            model: model
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    useEffect(() => {
        setHolding(isDragging)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDragging])

    if (refresh) {
        return (
            <div
                className={`${styles.chip}`}>
                <span>{label}</span>
            </div>
        )
    }
    return (
        <div
            ref={drag}
            className={`${styles.chip}`}>
            <span>{label}</span>
        </div>
    );
};