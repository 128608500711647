import styles from "./RevokePermission.module.css";

import Confirm from "./Confirm";

import { useState, useEffect } from "react";
import { useDrop } from "react-dnd";

const apiUrl = process.env.REACT_APP_ADMIN_API_URL;

export default function RevokePermission({ show, refresh }) {

    const [permission, setPermission] = useState({})

    const [loading, setLoading] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)

    useEffect(() => {
        if (isConfirm && permission) {
            setIsConfirm(false)
            handleRevokePermission(permission)
        }
        return () => {

        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConfirm, permission]);

    const handleRevokePermission = (item) => {
        const username = item.username;
        const model = item.model;
        const fetchPermissionRevoke = async () => {
            try {
                const response = await fetch(apiUrl + "/model/revoke", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('idAdminToken')}`
                    },
                    body: JSON.stringify({
                        "user_id": username,
                        "model":model
                    })
                })
                if (!response.ok) {
                    throw new Error('Request failed with status code: ' + response.status);
                }
                const data = await response.json();
                console.log(data);
                refresh();
                return data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        setLoading(true);
        fetchPermissionRevoke();
        setLoading(false)
    }

    const onDrop = (item) => {
        setPermission(item);
        setConfirm(true)
    }

    const onCancel = () => {
        setConfirm(false)
    }


    const [{ isOver }, drop] = useDrop({
        accept: 'permission',
        drop: (item) => onDrop(item),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });
    return (
        <div>
            {show && (!loading) ? (
                <div className={styles.delete_header}>
                    <div className={styles.delete_box}>
                        <div
                            ref={drop}
                            className={styles.container}
                        >
                            <p>Revoke</p>
                        </div>
                    </div>
                </div>
            ) : (<div />)}


            {true ? (
                <Confirm
                    show={confirm}
                    message={`Are you sure you want to revoke "${permission.username}" from "${permission.model}"?`}
                    handleConfirm={setIsConfirm}
                    handleClose={onCancel}
                />) : (<div />)}
        </div>


    );
}